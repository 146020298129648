import {
  LogLevel,
  Configuration,
  RedirectRequest,
  PublicClientApplication,
  BrowserAuthError,
} from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { ClientAuthError } from "msal";
import { logInfo, setRumUser } from "../../logging/ddUtils";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: "https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com",
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (_level, message) => {
        logInfo(message);
      },
      piiLoggingEnabled: false,
      logLevel: LogLevel.Error,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const scopes = [`${process.env.REACT_APP_SCOPES}`];

export const loginRequest: RedirectRequest = {
  scopes: scopes,
  domainHint: "exxonmobil.com",
};

export function getAccount() {
  const accounts = msalInstance.getAllAccounts();

  if (accounts && accounts.length > 0) {
    let account = accounts[0];
    let email = account.username;
    let name = account.name;
    setRumUser(email, name);
    return account;
  }
  return undefined;
}

export async function getAccessToken() {
  const account = getAccount();
  if (!account) {
    return undefined;
  }

  return msalInstance
    .acquireTokenSilent({ account: account, scopes: scopes })
    .then((results) => results.accessToken)
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        logInfo(
          "acquireTokenSilent failed, acquiring token using redirect"
        );
        return msalInstance.acquireTokenRedirect(loginRequest);
      }
      throw error;
    });
}
