import { TrackingConsent, User } from "@datadog/browser-core";
import { datadogLogs, LogsEvent, LogsEventDomainContext, StatusType } from "@datadog/browser-logs";
import { datadogRum, RumEvent, RumEventDomainContext } from "@datadog/browser-rum";
import { reactPlugin } from "@datadog/browser-rum-react";

let appName = `${process.env.REACT_APP_DD_SERVICE}`.toLowerCase()
let applicationId = `${process.env.REACT_APP_DD_RUM_APP_ID}`
let clientToken = 'puba1efd98505f2bb571f01dcca58c0cd48'

function applyTags(event: RumEvent | LogsEvent) {
    event['xom_env'] = 'release'
    event['xom_org'] = 'ee'
    event['xom_hosting_env'] = 'web_broswer'
    event['xom_hosting_type'] = 'browser'
    event['xom_support_group'] = 'developer-services-mobile-delivery'
    event['xom_support_group_dl'] = 'MobileDevDelivery_exxonmobil.com'
    event['xom_domain_app_id'] = 'app-15083'
    event['xom_app_name'] = appName
    event['env'] = 'mobility'
}

export function initializeDatadog() {
    datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        trackingConsent: TrackingConsent.GRANTED,
        trackUserInteractions: true,
        service: appName,
        beforeSend: (event: RumEvent, context: RumEventDomainContext) => {
            applyTags(event);
            return true;
        },
        plugins: [reactPlugin()]
    });

    datadogLogs.init({
        clientToken: clientToken,
        env: 'mobility',
        forwardConsoleLogs: 'all',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: appName,
        beforeSend: (event: LogsEvent, context: LogsEventDomainContext) => {
            applyTags(event);
            return true;
        }
    })

    datadogLogs.logger.setLevel(StatusType.debug);

    logInfo('datadog intialized')
}

export function logInfo(message: string, attributes: any = {}) {
    let attrs = attributes || {}
    datadogLogs.logger.info(
        message, 
        {
            ...attrs,
        }
    )
}

export function logError(message: string, error: Error) {
    datadogLogs.logger.error(
        message,
        {},
        error
    )
}

export function setRumUser(email: string | undefined, name: string | undefined) {
    let ddUser: User = {
        email: email,
        name: name
    };
    datadogRum.setUser(ddUser);
}